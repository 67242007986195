// import

import type {SVGProps} from 'react';

import * as Tabler from '@tabler/icons-react';

// types

type SvgComponent = Partial<SVGProps<SVGSVGElement>>;

export type IconProps = Omit<SvgComponent, 'stroke'> & {
  size?: number | string;
  stroke?: number | string;
};

export type Icon = (props: IconProps) => JSX.Element;

// export

export const IconChevronDown = Tabler.IconChevronDown as Icon;
export const IconEdit = Tabler.IconEdit as Icon;
export const IconTrash = Tabler.IconTrash as Icon;
export const IconClose = Tabler.IconX as Icon;
export const IconKey = Tabler.IconKey as Icon;
export const IconSocket = Tabler.IconPlug as Icon;
export const IconApi = Tabler.IconApi as Icon;
export const IconEllipsis = Tabler.IconDots as Icon;
export const IconPdfFile = Tabler.IconFileTypePdf as Icon;
export const IconChevronRight = Tabler.IconChevronRight as Icon;
export const IconPointFilled = Tabler.IconPointFilled as Icon;
