// import

import type {Icon} from './Icons';
import type {ComponentProps} from 'react';

import {forwardRef} from 'react';

import {cram} from '@cat/rambo';

import {Link} from './Link';

// types

type Size = 'large' | 'medium' | 'small';
type Color = 'blue' | 'gray' | 'green' | 'orange' | 'pink' | 'red';

export type ButtonProps = ComponentProps<'button'> & {
  size?: Size;
  color?: Color;
  icon?: Icon;
};

export type LinkButtonProps = ComponentProps<typeof Link> & {
  size?: Size;
  color?: Color;
  icon?: Icon;
  disabled?: boolean;
};

// styles

export const buttonCls = cram(
  'inline-block font-medium text-pinkZ-800 whitespace-nowrap',
  'rounded border-2 border-solid border-pinkZ-800',
);

// TODO: Support colors, sizes, and styles
export const buttonSizes: Record<Size, string> = {
  small: cram('h-1r6 leading-1r4 px-r6 text-r7'),
  medium: cram('h-2r6 leading-2r4 px-1r text-1r'),
  large: cram(),
};

export const buttonColors: Record<Color, string> = {
  red: cram(
    'bg-red-300 disabled:bg-redZ-300',
    'hover:bg-red-250 focus-visible:bg-red-250',
  ),
  orange: cram(
    'bg-orange-300 disabled:bg-orangeZ-300',
    'hover:bg-orange-250 focus-visible:bg-orange-250',
  ),
  green: cram(
    'bg-green-300 disabled:bg-greenZ-300',
    'hover:bg-green-250 focus-visible:bg-green-250',
  ),
  blue: cram(
    'bg-blue-300 disabled:bg-blueZ-300',
    'hover:bg-blue-250 focus-visible:bg-blue-250',
  ),
  pink: cram(
    'bg-pink-300 disabled:bg-pinkZ-300',
    'hover:bg-pink-250 focus-visible:bg-pink-250',
  ),
  gray: cram(
    'bg-pinkZ-175 disabled:bg-pinkZ-175',
    'hover:bg-pinkZ-125 focus-visible:bg-pinkZ-125',
  ),
};

// component

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {children, className, size = 'medium', color = 'pink', ...rest} = props;

  // TODO: Support icons
  return (
    <button
      type="button"
      {...rest}
      ref={ref}
      className={cram(
        buttonCls,
        buttonSizes[size],
        buttonColors[color],
        className,
      )}
    >
      {children}
    </button>
  );
});

export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>((props, ref) => {
  const {className, size = 'medium', color = 'pink', disabled, onClick, ...rest} = props;

  return (
    <Link
      {...rest}
      ref={ref}
      className={cram(
        buttonCls,
        buttonSizes[size],
        buttonColors[color],
        // TODO: Make tailwind plugin to also add support for `disabled` class
        disabled ? 'cursor-default' : '',
        className,
      )}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }

        if (disabled) {
          e.preventDefault();
        }
      }}
    />
  );
});
